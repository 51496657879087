.titulo-modal {
  color: #008425;
  font-weight: 700;
}

.modal {
  /* display: flex !important; */
  justify-content: center !important;
}
/* .modal-dialog {
  width: 450px !important;
} */
@media (min-width: 600px) {
  .modal-content {
    padding: 15px !important;
    width: 95% !important;
  }
}


@media (max-width: 600px) {
  .modal-dialog {
    width: 95% !important;
  }
}

@media (max-width: 500px) {
  .modal-dialog {
    width: 95% !important;
  }
}
