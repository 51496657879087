.patrocinio-cota {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.patrocinio-cota:last-child {
  padding-bottom: 155px !important;
}

.cota-titulo {
  font-weight: 800;
  color: #0d1d41;
}

.area-patrocinador {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  padding-bottom: 10px !important;
}

.patrocinador-item {
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0 !important;
}

.imagem-logo {
  height: auto;
  width: 150px;
}

.imagem-logo-modal {
  object-fit: contain;
  width: 200px;
}

.cota {
  font-weight: 800;
  color: #0d1d41;
}

.modal-perfil {
  display: flex;
  padding: 5px;
}

.texto-modal-perfil {
  margin-left: 15px;
}
.linha-contatos {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 90%;
  margin-top: 2px;
}

.label-contatos {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: 18px;
  font-weight: 700;
}

@media (max-width: 600px) {
  .modal-perfil {
    display: block;
    text-align: center;
  }
  .texto-modal-perfil {
    margin-top: 10px;
  }
  .label-contatos {
    display: flex;
    width: 90%;
    justify-content: flex-start;
    font-size: 16px;
    font-weight: 700;
  }

  .linha-contatos {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 2px;
    font-size: 13px;
    margin-right: 10px;
  }
  .span-nome {
    display: none;
  }
}
