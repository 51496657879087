.area-dias {
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  background-color: #fff;
}

.item-dias {
  margin: 6px;
  border: 1px solid #6c6c6c;
  border-radius: 5px;
  width: 65px;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: 700;
  color: #6c6c6c;
  cursor: pointer;
}

.dia-selecionado {
  color: #fff;
  background-color: #0d1d41;
}

#area-agenda {
  overflow: scroll;
  padding-bottom: 90px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 140px;
}

.area-vazia {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 18px;
  font-size: xx-large;
  font-weight: 700;
  text-align: center;
  color: #0d1d41;
}

#area-agenda::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

#area-agenda::-webkit-scrollbar-track {
  background: rgb(255, 255, 255); /* color of the tracking area */
}

#area-agenda::-webkit-scrollbar-thumb {
  background-color: rgb(182, 182, 182); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid rgb(182, 182, 182); /* creates padding around scroll thumb */
}

.item-agenda {
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icone-agenda {
  padding: 15px;
}

.titulo-agenda {
  color: #878787;
  font-weight: 700;
}
.titulo-selecionado {
  color: #0d1d41;
}

.detalhe-agenda {
  color: #a7a6a6;
  font-size: 14px;
}

.area-agenda-detalhe {
  margin-top: 10px;
}

.dados-agenda {
  width: 100%;
}

.area-participantes {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}

.participante-agenda {
  display: flex;
  margin-right: 10px;
  margin-top: 5px;
}
