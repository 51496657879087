#container-header {
  background-image: url("../../assets/header/1-Cabeçalho-liso-560x104.png");
  /* background-image: url('../../assets/header/fundo_header.png'); */
  height: 60px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 99999;
}

#logo-header {
  width: 95px;
  margin-top: 5px;
}

#icone-menu {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 18px;
  margin-left: 15px;
  cursor: pointer;
}

#agenda-header {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  margin: 15px;
  margin-right: 55px;
  color: #018a38;
  background-color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#notificacoes-header {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  margin: 15px;
  margin-right: 20px;
  color: #018a38;
  background-color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-pesquisa-negocio {
  background-color: #0d8178;
  color: #fff;
  font-size: 22px;
  font-weight: 800;
  padding: 5px 55px;
  border-radius: 5px;
  margin: 5px;
  text-align: center;
  cursor: pointer;
}

.btn-pesquisa-negocio.desabilitado {
  background-color: #0d817873;
}

.area-btn-pesquisa {
  margin-top: 15px;
}
.texto-pesquisa {
  font-size: 24px;
  font-weight: 800;
  color: #6c757d;
  text-align: center;
}
.aviso-pesquisa {
  color: #6c757d;
  text-align: center;
  font-style: italic;
  font-weight: 200;
}

.area-btn-star {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-pesquisa-negocio-star {
  font-size: 50px;
  color: #007f1d;
  margin: 3px;
}

.btn-pesquisa-negocio.escolhido {
  background-color: #8abb2a;
  color: #fff;
  font-size: 22px;
  font-weight: 800;
  padding: 5px 55px;
  border-radius: 5px;
  margin: 5px;
  text-align: center;
  border: 1px solid #007f1d;
}
