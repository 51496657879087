.area-postagem {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #d8d7d7b4;
  box-shadow: -1px 2px 6px 0px rgba(22, 17, 17, 0.08), 0 0 8px #cecdcdbf;
  padding: 8px;
  justify-content: center;
  position: fixed;
  width: 100%;
  background-color: #fff;
}

.area-input {
  display: flex;
  align-items: center;
}

.input-postagem {
  width: 100%;
}
.input-postagem input {
  width: 100%;
}
.input-postagem input::placeholder {
  font-weight: 600;
  padding-left: 10px;
}

.area-botoes {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 6px;
}

.btn-camera {
  width: 40px;
  margin-left: 8px;
}

.btn-enviar {
  font-size: 12px;
  font-weight: 600;
  border-radius: 8px;
  background-color: #0d1d41 !important;
  height: 30px;
  width: 60px;
}

.area-btn-minha-agenda {
  border-bottom: 1px solid #d8d7d7b4;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 8%), 0 0 0px #cecdcdbf;
  padding: 10px;
  text-align: center;
  width: 90%;
}

.btn-minha-agenda {
  font-size: 12px;
  font-weight: 600;
  border-radius: 8px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 1px;
  padding-bottom: 1px;
  background-color: #008c39 !important;
}

.area-timeline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* overflow-y: auto; */
  margin-top: 145px;
}

#listagem-timeline {
  width: 95%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  /* overflow: scroll; */
  height: 70vh;
  padding-bottom: 90px;
  margin-top: 5px;
  margin-bottom: 5px;
}

#listagem-timeline::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

#listagem-timeline::-webkit-scrollbar-track {
  background: rgb(255, 255, 255); /* color of the tracking area */
}

#listagem-timeline::-webkit-scrollbar-thumb {
  background-color: rgb(182, 182, 182); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid rgb(182, 182, 182); /* creates padding around scroll thumb */
}

.item-timeline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: solid 1px rgb(211, 211, 211);
  width: 98%;
}

.item-timeline:last-child .area-imagem-descricao-botoes {
  margin-bottom: 150px;
}

.area-participante-item-timeline {
  display: flex;
  padding: 10px;
}

.dados-participante-timeline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 15px;
  min-width: 140px;
}

.nome-participante-timeline {
  font-size: 17px;
  font-weight: 700;
  color: #545454;
}

.empresa-participante-timeline {
  font-size: 14px;
  font-weight: 600;
  margin-top: -8px;
  color: #545454;
}
.area-imagem-descricao-botoes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.area-descricao-botoes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  color: #545454;
  font-weight: 600;
  font-size: small;
}

.area-botoes-timeline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.botoes-feed {
  width: 30px;
  margin-right: 5px;
}

.imagem-feed {
  width: 100%;
}

#input-camera {
  display: none;
}

.bg-green {
  background-color: #9dbf1c;
  border: #008c39;
  font-size: 17px;
  font-weight: 600;
  height: 30px;
  width: 60px;
  margin-right: 5px;
  padding: 0 !important;
  color: #fff;
}

.bg-green:hover {
  background-color: #008c39;
  border: #008c39;
  color: #fff;
}
.area-preview {
  display: flex;
  justify-content: center;
  border: 1px solid #0d1d41;
  padding: 2px;
}

.area-postagem.area-postagem-loading {
  /* filter: blur(2px); */
}

.area-preview img {
  width: 290px;
  margin: 5px;
}
.area-loading-blur {
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgb(255 255 255 / 67%);
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 9999;
}
.area-loading {
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 9999;
}
.area-loading img {
  height: 10%;
}

.icone-post {
  color: #fff;
  width: 30px;
  background-color: #606060;
  height: 30px;
  padding: 7px;
  border-radius: 6px;
  margin-right: 3px;
  cursor: pointer;
}

.icone-post-excluir {
  color: #fff;
  width: 30px;
  background-color: #606060;
  height: 30px;
  padding: 7px;
  border-radius: 6px;
  margin-right: 3px;
  cursor: pointer;
}

.icone-post-excluir:hover {
  color: #fff;
  width: 30px;
  background-color: #ff0303;
  height: 30px;
  padding: 7px;
  border-radius: 6px;
  margin-right: 3px;
  cursor: pointer;
}

.marcado {
  background-color: #018a38 !important;
}

.area-comentarios {
  padding-top: 5px;
  padding-left: 5px;
  border-top: 1px solid rgba(153, 153, 153, 0.671);
  width: 100%;
}

.item-comentario {
  display: flex;
  flex-direction: column;
}
.nome-remetente {
  display: flex;
  justify-content: space-between;
  font-weight: 800;
  margin-bottom: -3px;
}

.area-comentario {
  padding-left: 10px;
  margin-bottom: 5px;
}

.texto-quantidades {
  display: flex;
}

.area-botoes-acoes {
  width: 135px;
}

.label-preview {
  font-size: 12px;
  color: #827a7a;
  font-weight: 500;
}
