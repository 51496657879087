.item-notificacao-modal {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.item-notificacao-modal:not(:first-child) {
  border-top: solid 3px #dddddd;
  padding-top: 15px;
  margin-top: 25px;
}

.titulo-item-notificacao-modal {
  color: #0d1d41;
  font-weight: 500;
}
.body-item-notificacao-modal {
  color: #6c6c6c;
}

.corpo-notificacao {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  padding-bottom: 8px !important;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}
.corpo-notificacao:hover {
  background-color: #ccc;
}
.texto-notificacao {
  padding: 5px !important;
  font-weight: 500;
}

.data-notificacao {
  font-size: 12px;
  align-self: flex-end;
  padding-bottom: 2px !important;
  margin-right: 10px;
}
