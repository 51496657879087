.container-recepcao{
    padding: 15px;
    width: 100%;
    margin-top: 60px;
    height: 100%;
}

.header-recepcao{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.area-lista-reunioes{
    overflow-y: auto;
    height: 100%;
    padding: 10px 15px;
    padding-bottom: 180px;
}

.linha-agenda{
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    padding-top: 5px;
    cursor: pointer;
    border-radius: 5px;
}

.linha-agenda:hover{
    background-color: #ccc;
}

.label-agenda{
    font-weight: 600;
    padding: 5px;
}
.label-agenda-modal{
    font-weight: 600;
    text-align: center;
}

.label-hora{
    color: #007f1d;
    font-weight: 800;
}

.label-hora-modal{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #007f1d;
    font-weight: 800;
    width: 40%;
    margin-right: 5px;
}

.area-reunia-nogocio{
    display: flex;
    align-items: center;
}

.separador-negocios{margin-left: 15px;margin-right: 15px}


.area-input-modal{
    width: 100%;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.input-verificador{
    text-transform: uppercase;
    text-align: center;
}
.area-botao-modal{
    margin-top: 15px;
}

.area-modal-validacao{
    margin-top: 15px;
}
.titulo{
    display: block;
}

@media (max-width: 720px) {
    .titulo{
        display: none;
    }
}

.area-participante{
    display: none;
    background-color: #fff;
    padding: 15px;
}

.area-participante.ativo{
    display: block;
}

.linha-participante-conteudo{
    border-bottom: 1px solid #cccccc;
    padding: 5px;
    display: flex;
    align-items: center;
}

.area-botao-abrir{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
}

.area-texto-conteudo{
    width: 80%;
}

.icone-check{
    color: #007f1d;
    margin-right: 10px;
}
.area-nome-evento{
    display: flex;
    justify-content: space-between;
}