body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#content-wrapper {
  height: 100%;
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-image: url("../../assets/2560x1140-azul.png");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}

.link-padrao {
  text-decoration: none;
  color: #008425;
  font-weight: 500;
}

.titulo-pagina {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  color: #008425;
  margin-top: 10px;
}
.titulo-pagina span {
  color: #6c6c6c;
}

.corpo-pagina {
  padding-left: 5px;
  padding-right: 5px;
  color: #6c6c6c;
  margin-bottom: 55px;
  height: 100%;
}

.corpo-pagina div:last-child {
  padding-bottom: 55px;
}

.espaco-apple {
  padding-top: 50px !important;
}

.body-conversa.espaco-apple {
  height: calc(100% - 145px) !important;
}

.area-timeline.espaco-apple {
  padding-top: 145px !important;
}

.footer-conversa.espaco-apple {
  margin-bottom: 90px !important;
}

.espaco-footer {
  height: 150px;
  padding-bottom: 60px;
}
.espaco-footer-apple {
  height: 150px;
  padding-bottom: 85px;
}
