#container-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 99999;
}

.mostrar-menu {
  transform: translateX(0);
  transition: all 400ms ease-in;
}
.esconder-menu {
  transform: translateX(-100%);
  transition: all 300ms ease-in;
}

#menu-title {
  background-color: #008c39;
  display: flex;
  height: 58px;
  align-items: center;
  padding-left: 55px;
  padding-top: 5px;
  color: #fff;
}
#btn-logout {
  position: absolute;
  right: 0;
  top: -1px;
  margin-right: 20px;
  font-size: xx-large;
  cursor: pointer;
}

#menu-body {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
}

#menu-botao {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 15px;
  margin-top: 15px;
  width: 27px;
  height: 22px;
  cursor: pointer;
}

.container-item-menu {
  width: 70px;
  height: 105px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 12px;
  flex-direction: column;
  cursor: pointer;
}

.item-menu-icone {
  /* background-color: #008C39; */
  width: 65px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.item-menu-icone-label {
  background-color: #008c39;
  width: 65px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 40px;
  color: #fff;
}

.item-menu-label {
  margin-top: 5px;
  color: #007f1d;
  font-size: 12px;
  font-weight: 700;
  flex: 1;
  text-align: center;
}

.link-menu {
  text-decoration: none;
}

.menu-modal {
  font-size: 60px;
  font-weight: 700;
  color: #007f1d;
}
