.mapa-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 130px;
}

.mapa {
  width: 95%;
  height: auto;
  object-fit: cover;
  /* position: absolute; */
  margin-top: 20px;
}

@media (min-width: 1024px) {
  .mapa-container {
    flex-direction: row;
    justify-content: space-around;
  }
  .mapa {
    width: 45%;
  }
}
