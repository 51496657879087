.container-cadastro {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 55px;
}

.barra-header {
  background-color: #8abb2a;
  min-height: 25px;
  width: 100%;
  margin-bottom: 25px;
}

.texto-cadastro {
  margin-left: 20px;
  margin-right: 20px;
  max-width: 1125px;
  text-align: center;
  color: #7f7f7f;
  margin-bottom: 55px;
}
.text-red {
  color: red;
}

.requered-error {
  border-color: red !important;
}

.label-input {
  color: #7f7f7f;
  margin-top: 15px;
}

.area-inputs {
  width: 90%;
}

.area-botoes-cadastro {
  width: 60%;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 45px;
}

.botoes-cadastro {
  padding: 5px 30px;
  font-weight: 500;
}

.coluna-dupla {
  display: flex;
  width: 100%;
  justify-content: center;
}

.ddd {
  width: 150px !important;
}

.coluna-dupla .mb-1 {
  width: 100% !important;
}

.espacador {
  width: 25px;
}

.area-radio-cadastro {
  margin-top: 15px;
}

.area-radio-cadastro,
input[type="radio"] {
  padding: 0 !important;
  min-height: 0 !important;
}

.area-radio-cadastro,
label {
  color: #7f7f7f;
}

.radio-cadastro:checked {
  background-color: #008c39;
  border-color: #008c39;
}

.area-alerta {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 5px;
  padding: 25px;
  margin-top: 25px;
  margin-bottom: 15px;
  color: rgb(167, 1, 1);
  font-weight: 500;
  border: 1px solid rgb(167, 1, 1);
  animation-name: exampleAlert;
  animation-duration: 4s;
}

.area-bio {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 80%;
}
.area-texto-bio {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
}
.textarea-bio {
  border-radius: 5px;
  border-color: #8abb2a;
}

.area-foto {
  /* display: flex; */
  justify-content: space-between;
}

.input-foto {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.input-file {
  border-color: transparent;
  cursor: pointer;
}

.preview-foto {
  border: 1px solid #8abb2a;
  border-radius: 5px;
  padding: 15px;
  color: #7f7f7f;
  display: flex;
  justify-content: center;
  align-items: center;
}

.foto {
  max-width: 250px;
}

.area-bloco-conteudos {
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.bloco-conteudo {
  margin: -20px 20px 40px 20px;
  border: 1px solid #8abb2a;
  border-radius: 5px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
}

.area-conteudos-23 {
  padding: 10px;
  border-radius: 5px;
  margin-top: 15px;
}

.bloqueado {
  background-color: #ccc;
  cursor: no-drop !important;
}

.header-bloco-conteudo {
  font-weight: 700;
  text-align: center;
  margin-top: 5px;
  margin-bottom: -22px;
  padding-top: 8px;
}

.area-conteudos {
  display: flex;
  flex-direction: column;
}

.conteudo {
  padding: 10px;
  border: 1px solid #8abb2a;
  border-radius: 5px;
  margin: 10px 10px 2px 10px;
  font-weight: 600;
  cursor: pointer;
  transition: 200ms;
  height: 150px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.conteudo.ativo {
  background-color: #0d8178 !important;
  color: #fff;
  transition: 200ms;
}

/* .conteudo:hover {
  background-color: #8abb2a;
  color: #fff;
  transition: 200ms;
} */

.bloco-conteudo-esporte {
  margin: -20px 20px 40px 20px;
  border: 1px solid #8abb2a;
  border-radius: 5px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.header-bloco-conteudo-esporte {
  font-weight: 700;
  text-align: center;
  margin-top: 5px;
  margin-bottom: -5px;
}

.area-conteudos-esporte {
  display: flex;
  flex-direction: column;
}

.conteudo-esporte {
  padding: 30px;
  border: 1px solid #8abb2a;
  border-radius: 5px;
  margin: 10px 10px 2px 10px;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 200ms;
  text-align: center;
}
.conteudo-esporte.ativo {
  background-color: #0d8178;
  color: #fff;
  transition: 200ms;
}

.conteudo-esporte-bloqueado {
  background-color: #ccc;
  cursor: no-drop !important;
  padding: 30px;
  border: 1px solid #8abb2a;
  border-radius: 5px;
  margin: 10px 10px 2px 10px;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 200ms;
  text-align: center;
  transition: 200ms;
}

.conteudo-esporte:hover {
  background-color: #0d8178;
  color: #fff;
  transition: 200ms;
}

.logo-termo {
  width: 200px;
}

.modal-90w {
  width: 60%;
  max-width: 90% !important;
}

.modal-90w .modal-header {
  border-bottom: none;
  /* background-color: #8abb2a; */
  height: 20px;
}

.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  --bs-modal-padding: 5px !important;
}
.area-texto-modal {
  padding: 15px;
  margin-top: 10px;
}
.area-check-box {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 30px;
}
.label-checkbox {
  margin-left: 5px;
  cursor: pointer;
}
.area-botao-modal {
  display: flex;
  width: 100%;
  justify-content: center;
}

.titulo-eventos {
  width: 80%;
  text-align: center;
}

/* scroll */

.form-outline .form-control:focus ~ .form-notch .form-notch-leading {
  border-top: 0.125rem solid #008c39;
  border-bottom: 0.125rem solid #008c39;
  border-left: 0.125rem solid #008c39;
}

.form-outline .form-control:focus ~ .form-notch .form-notch-middle {
  border-bottom: 0.125rem solid;
  border-color: #008c39;
}

.form-outline .form-control:focus ~ .form-notch .form-notch-trailing {
  border-color: currentcolor currentcolor currentcolor #008c39;
  border-bottom: 0.125rem solid #008c39;
  border-right: 0.125rem solid #008c39;
  border-top: 0.125rem solid #008c39;
}

.form-outline .form-control:focus ~ .form-label {
  color: #008c39;
}

.oculto {
  display: none;
}

select:focus,
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: rgba(0, 138, 56, 0.8);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(126, 239, 104, 0.6);
  outline: 0 none;
}

.form-check-input:checked {
  background-color: #008c39;
  border-color: #008c39;
}

.form-control-lg {
  min-height: calc(1.2em);
  font-size: 1rem;
  border-radius: 0.3rem;
  width: 100%;
}

.container-cadastro::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

.container-cadastro::-webkit-scrollbar-track {
  background: rgb(255, 255, 255); /* color of the tracking area */
}

.container-cadastro::-webkit-scrollbar-thumb {
  background-color: rgb(182, 182, 182); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid rgb(182, 182, 182); /* creates padding around scroll thumb */
}

.bt-mais-detalhes-evento {
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 20px;
  margin-bottom: 10px;
  font-size: 22px;
}

.area-conteudo-icone {
  position: relative;
}

.evento-cheio {
  background-color: #ccc;
  border-color: #ccc;
}

.evento-cheio:hover {
  background-color: #ccc;
  border-color: #ccc;
}

.area-participantes-patrocinador {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #8abb2a;
}

.header-participantes-patrocinadores {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.body-participantes-patrocinador {
  border-top: 1px solid #ccc;
  text-align: center;
  padding: 3px;
}

.area-botoes-cadastro-patrocinador {
  width: 90%;
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
  margin-bottom: 45px;
}

.area-inputs-modal {
  width: 100%;
}

.linha-participantes {
  display: flex;
  justify-content: space-around;
  transition: 150ms;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  padding: 5px;
  font-weight: 500;
}

.linha-participantes > div {
  text-align: center;
  width: 100%;
}

.linha-participantes:hover {
  background-color: #008c39;
  transition: 150ms;
}

.linha-participantes-header {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #8abb2a;
  font-weight: 700;
  padding: 3px;
}

.linha-participantes-header > div {
  text-align: center;
  width: 100%;
  padding-bottom: 5px;
}

.btn-participantes {
  display: flex;
  background-color: #0d1d41;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  cursor: pointer;
  color: #fff;
  transition: 150ms;
  text-decoration: none;
  padding: 4px;
  margin-left: 5px;
}
.btn-participantes:hover {
  color: #fff;
  background-color: #8abb2a;
  transition: 150ms;
}
.area-botoes-participantes {
  display: flex;
  justify-content: center;
  align-items: center;
}

.area-texto-participantes {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 15px;
  border-radius: 5px;
}

.erro-formulario {
  color: red;
  padding-bottom: 5px !important;
}

.input-erro {
  border-color: red;
}

.bt-loading {
  background-color: #f1f2f3 !important;
}

.area-checkbox {
  display: flex;
  width: 200px;
  justify-content: space-evenly;
}

.label-check {
  margin-left: 5px;
}

@media (max-width: 600px) {
  .linha-participantes-header {
    display: none;
  }
  .linha-participantes {
    font-size: 12px;
  }

  .nome-cracha {
    display: none;
  }
  .data-nascimento {
    display: none;
  }

  .area-participantes-patrocinador {
    padding: 5px !important;
    width: 97% !important;
  }

  .email {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}
