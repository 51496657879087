#root {
  width: 100vw;
  height: 100vh;
}

#container-login-verde {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  background-image: url("../../assets/login/bg/2560x1140-verde.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-attachment: fixed;
  background-size: cover;
  overflow: hidden;
  object-fit: cover;
  align-items: center;
}

#container-central-verde {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -130px;
}

#container-inputs-verde {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
  min-height: 140px;
  max-width: 550px;
  z-index: 100;
}

#container-inputs-verde input {
  width: 75%;
  margin-bottom: 10px;
}

#container-inputs-verde input::placeholder {
  color: #0D1D41;
}

#container-inputs-verde input[type="text"]:focus,#container-inputs-verde input[type="password"]:focus {
  border-color: #0D1D41;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #0D1D41;
  outline: 0 none;
}

#botao-acessar-verde {
  background-color: #0D1D41;
  font-weight: 700;
}

#img-logo-verde {
  width: 400px;
  margin-bottom: 8px;
}

@media (max-width: 1440px) {
  #container-login-verde {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    background-image: url("../../assets/login/bg/1920x1080-verde.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-attachment: fixed;
    background-size: cover;
    overflow: hidden;
    object-fit: cover;
    align-items: center;
  }
}

@media (max-width: 1024px) {
  #container-login-verde {
    background-image: url("../../assets/login/bg/960x540-verde.png");
    background-size: contain;
    background-color: #85b639;
  }

  #img-logo-verde {
    width: 350px;
    margin-bottom: 8px;
  }
}

@media (max-width: 1024px) and (min-height: 1366px) {
  #container-login-verde {
    background-image: url("../../assets/login/bg/2560x1140-verde.png");
    background-position: left;
    background-size: cover;
  }

  #img-logo-verde {
    width: 350px;
    margin-bottom: 8px;
  }
}

@media (max-width: 853px) {
  #container-login-verde {
    background-image: url("../../assets/login/bg/2560x1140-verde.png");
    background-position: left;
    background-size: cover;
  }

  #img-logo-verde {
    width: 350px;
    margin-bottom: 8px;
  }
}

@media (max-width: 768px) {
  #container-login-verde {
    background-image: url("../../assets/login/bg/2560x1140-verde.png");
    background-position: left;
    background-size: cover;
  }

  #img-logo-verde {
    width: 350px;
    margin-bottom: 8px;
  }
}

@media (max-width: 720px) {
  #container-login-verde {
    background-position: right;
  }

  #img-logo-verde {
    width: 270px;
    margin-bottom: 8px;
  }
}