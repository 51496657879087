.pesquisa {
  box-shadow: none !important;
}

.area-filtro {
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  background-color: #fff;
  padding-bottom: 5px !important;
  margin-bottom: 5px;
}

.area-filtro .input-group {
  padding-bottom: 5px !important;
}

#lista-participantes {
  margin-top: 90px;
  /* overflow: scroll; */
  padding-bottom: 260px;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.item-participante {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 15px; */
  border-bottom: solid 1px rgb(211, 211, 211);
  padding-bottom: 5px !important;
  min-height: 100px;
}

.item-participante:last-child {
  padding-bottom: 165px !important;
  margin-top: 15px;
}

.empresa-participante {
  padding-bottom: 5px !important;
}

.imagem-participante {
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
  height: 4.1rem;
  width: 4.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  min-width: 4.1rem;
  object-fit: cover;
}

.imagem-participante-pequeno {
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  object-fit: cover;
  transition: all 500ms;
  /* border-bottom: 1px solid #fff; */
  position: absolute;
  margin-left: -175px;
  cursor: pointer;
  margin-bottom: 10px;
}

.imagem-participante-pequeno-modal {
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
  height: 50px;
  width: 50px;
  display: flex;
  min-width: 50px;
  object-fit: cover;
  transition: all 500ms;
  /* border-bottom: 1px solid #fff; */
  margin-bottom: 10px;
}

.imagem-participante-grande {
  border-radius: 10%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
  height: 335px;
  width: 335px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  min-width: 50px;
  object-fit: cover;
  transition: all 500ms;
  position: absolute;
  margin-left: 55px;
  z-index: 1;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 7px;
}

.imagem-participante-grande-modal {
  border-radius: 10%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
  height: 335px;
  width: 335px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 15px; */
  min-width: 50px;
  object-fit: contain;
  transition: all 500ms;
  position: absolute;
  /* margin-left: 55px; */
  z-index: 1;
  cursor: pointer;
  margin-bottom: 10px;
  background-color: #fff;
  padding: 7px;
  color: #000;
}

.link-whatsapp {
  position: relative;
  top: -30px;
  right: 10px;
}
.link-whatsapp-none {
  display: none;
}

.participante-agenda-pequeno {
  display: flex;
  flex-direction: row;
  /* margin-right: 10px; */
  justify-content: center;
  flex: 1;
  color: #000;
}

.participante-agenda-pequeno-modal {
  display: flex;
  margin-top: 10px;
}

.participante-agenda-grande {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  margin-top: 5px;
  justify-content: center;
}

.dados-participante {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  padding-bottom: 5px !important;
  /* width: 185px; */
  color: #000;
}

.dados-participante-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  padding-bottom: 5px !important;
  width: 100%;
}

.dados-patrocinador {
  border-top: 1px solid #000;
  color: #5c5c5c;
  font-weight: 800;
  width: 100%;
  margin-top: 5px;
}

.item-participante-modal:hover .dados-patrocinador {
  color: #404040;
}

.botao-participante {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 130px;
  padding-bottom: 5px !important;
}
.btn-enviar-mensagem {
  font-size: 12px;
  border-radius: 8px;
  background-color: #0d1d41 !important;
}

.nome-participante {
  font-size: 14px;
  font-weight: 700;
  line-height: 90%;
}

.empresa-participante {
  font-size: 13px;
  font-weight: 600;
  line-height: 85%;
  margin-top: 2px;
}

#input-group-dropdown-participantes {
  background-color: #0d1d41 !important;
  color: #fff;
  box-shadow: none !important;
}

#lista-participantes::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

#lista-participantes::-webkit-scrollbar-track {
  background: rgb(255, 255, 255); /* color of the tracking area */
}

#lista-participantes::-webkit-scrollbar-thumb {
  background-color: rgb(182, 182, 182); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid rgb(182, 182, 182); /* creates padding around scroll thumb */
}

.area-dados {
  width: 100%;
  display: flex;
  align-items: center;
}

@media (max-width: 320px) {
  .imagem-participante {
    display: none;
  }
  .label-mensagem {
    display: none;
  }
  .btn-enviar-mensagem {
    font-size: 16px;
    border-radius: 8px;
    background-color: #0d1d41 !important;
    margin-right: 15px;
  }
  .botao-participante {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 50px;
  }
}

@media (max-width: 375px) {
  .imagem-participante {
    height: 45px;
    width: 45px;
    min-width: 45px;
  }
  .label-mensagem {
  }
  .btn-enviar-mensagem {
  }

  .botao-participante {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 130px;
  }
}
