.area-usuario {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 15px;
  height: 90px;
}

.dados-usuario {
  font-size: 20px;
  padding-bottom: 15px !important;
}

.nome-usuario {
  font-weight: 700;
}

.empresa-usuario {
  font-size: 14px;
}

.pin-usuario {
  font-size: 15px;
  font-weight: 500;
}

.area-inputs-dados {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.area-inputs-dados .mb-1 {
  width: 90%;
}

.form-check-inline {
  padding-bottom: 5px !important;
}

.area-texto-bio,
.bio {
  padding-bottom: 5px !important;
}

.area-botoes-perfil {
  justify-content: center;
  padding-bottom: 125px !important;
  display: flex;
}

.alinhado-centro {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.imagem-participante-perfil {
  cursor: pointer;
  margin: 0 !important;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
  height: 4.1rem;
  width: 4.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 4.1rem;
  object-fit: cover;
}

.link-alterar {
  cursor: pointer;
  color: #0d1d41;
  margin-top: 15px;
}

.link-alterar:hover {
  cursor: pointer;
  color: #008c39;
}

.area-foto-perfil {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  margin-top: 20px;
}

.btn-alterar {
  background-color: #9dbf1c;
  border: #008c39;
  font-size: 17px;
  font-weight: 600;
  margin-right: 5px;
  margin-top: 5px;
  padding: 5px;
  color: #fff;
  cursor: pointer;
}

#input-perfil {
  display: none;
}
.area-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
}
