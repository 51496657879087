body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes shake {
  0% {
    -webkit-transform: translate(2px, 1px) rotate(0deg);
    transform: translate(2px, 1px) rotate(0deg);
  }

  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-2deg);
    transform: translate(-1px, -2px) rotate(-2deg);
  }

  20% {
    -webkit-transform: translate(-3px, 0) rotate(3deg);
    transform: translate(-3px, 0) rotate(3deg);
  }

  30% {
    -webkit-transform: translate(0, 2px) rotate(0deg);
    transform: translate(0, 2px) rotate(0deg);
  }

  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    -webkit-transform: translate(2px, 1px) rotate(-2deg);
    transform: translate(2px, 1px) rotate(-2deg);
  }

  80% {
    -webkit-transform: translate(-1px, -1px) rotate(4deg);
    transform: translate(-1px, -1px) rotate(4deg);
  }

  90% {
    -webkit-transform: translate(2px, 2px) rotate(0deg);
    transform: translate(2px, 2px) rotate(0deg);
  }

  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.wrapper {
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
}

.main {
  margin-top: 60px;
  margin-bottom: 55px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: scroll;
}
