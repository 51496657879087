#container-footer {
  background-image: url("../../assets/footer/2-Rodapé.png");
  /* background-image: url("../../assets/footer/fundo_footer.png"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 50px;
  position: fixed;
  width: 100%;
  bottom: 0;
}

.icone-footer {
  margin: 15px;
}

#container-footer-icones {
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}
