.container-adm {
  display: flex;
  overflow-y: auto;
}

.container-adm-main {
  height: 100%;
  margin-top: 60px;
  padding: 10px 25px;
  overflow-y: auto;
}

.menu-adm {
  display: flex;
  flex-direction: column;
  width: 200px;
  background-color: #008c39;
  height: 100vh;
  position: fixed;
}

.item-menu-adm {
  min-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #fff;
  padding: 10px;
  font-weight: 500;
}

.item-menu-adm:hover {
  text-decoration: none;
  color: #fff;
  background-color: #9dbf1c;
  transition: 300ms;
}

.item-menu-adm.ativo {
  background-color: #9dbf1c;
}

.area-conteudo-adm {
  margin: 5px 15px;
  width: 100%;
  margin-left: 220px;
  /* height: 100vh; */
}

table {
  width: 100%;
}

thead th {
  padding: 10px;
  border: 1px solid rgb(177, 177, 177) !important;
  background-color: #ccc;
}

.text-area-notificacao {
  width: 100%;
  border-radius: 5px;
  margin-top: 5px;
}

.area-botoes-notificacao {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.btn-reenviar {
  font-size: 12px;
  font-weight: 600;
  border-radius: 8px;
  background-color: #0d1d41 !important;
  height: 30px;
}

.label-notificacao {
  font-weight: 500;
}

.header-tela-adm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.header-options {
  display: flex;
  align-items: center;
  /*width: 100%;*/
  justify-content: flex-end;
}

.btn-header-options {
  background-color: #0d1d41;
  display: flex;
  align-items: center;
  padding: 3px 8px;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  transition: 150ms;
  text-decoration: none;
  margin-right: 5px;
}

.btn-header-options:hover {
  color: #fff;
  background-color: #264893;
  transition: 150ms;
}

.btn-header-options.disabled {
  color: #fff;
  background-color: #89a2d6;
}

.lista-adm {
  width: 100%;
}

.header-lista-adm {
  display: flex;
  width: 100%;
  justify-content: space-around;
  text-align: center;
  border-bottom: 1px solid #ccc;
  font-weight: 500;
}
.header-lista-adm > div {
  width: 100%;
}

.row-lista-adm {
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 2px;
  padding: 4px;
  transition: 150ms;
  border-bottom: 1px solid #ccc;
}
.row-lista-adm.ativo {
  background-color: #9dbf1c !important;
}

.row-lista-adm:hover {
  background-color: #008c39 !important;
  transition: 300ms;
}

.row-lista-adm:hover a {
  color: #fff;
  text-decoration: underline;
}

.area-selecao-reuniao {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background-color: #9dbf1c !important;
}

.row-lista-adm > div {
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.area-botoes {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.opcoes-lista-adm {
  display: flex !important;
  justify-content: center !important;
}

.opcoes-lista-adm a {
  margin-left: 5px;
}

.opcoes-lista-adm a svg {
  margin-right: 5px;
}

.botoes-cadastro-salvar {
  background-color: #0d1d41 !important;
}

.botoes-cadastro-salvar:hover {
  background-color: #264893 !important;
}

.area-botoes-lista-adm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  width: 100%;
}

.modal {
  --bs-modal-width: 90% !important;
}

.area-modal-reuniao {
  display: flex;
  width: 100%;
}

.area-filtro-reuniao {
  display: flex;
  flex-direction: column;
  background-color: #ccc;
  border-radius: 5px;
  width: 30%;
  padding: 5px;
}

.area-lista-convidado {
  width: 70%;
  margin-left: 5px;
}

.btn-header-options.ativo {
  background-color: #264893 !important;
}

.body-modal-participantes-evento {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.item-participante-modal {
  background-color: #fff;
  border: 1px solid #6c757d;
  border-radius: 5px;
  color: #000;
  font-weight: 400;
  margin: 15px;
  padding: 10px;
  text-align: center;
  transition: 0.25s;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.item-participante-modal.entregue {
  background-color: #0d8178;
  color: #fff;
}
.item-participante-modal.no_show {
  background-color: #6c6c6c;
  color: #fff;
}

.item-participante-modal:hover {
  border: 1px solid #9dbf1c;
  background-color: #6c757d;
  transition: 250ms;
}

.item-participante-modal.entregue .dados-patrocinador {
  color: #fff !important;
}

.item-participante-modal.entregue .nome-convidado-adm {
  color: #fff !important;
}

.item-participante-modal.no_show .dados-patrocinador {
  color: #fff !important;
}

.item-participante-modal.no_show .nome-convidado-adm {
  color: #fff !important;
}

.item-participante-modal:hover .dados-patrocinador {
  color: #fff !important;
}

.item-participante-modal:hover .nome-convidado-adm {
  color: #fff !important;
}

.area-imagem-usuario {
  padding: 5px;
  display: flex;

  align-items: center;
}

.area-imagem-usuario img {
  width: 350px;
  margin-bottom: 5px;
}

.form-imagem {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
}

.form-lateral {
  width: 70%;
}

.textarea-bio {
  width: 100%;
}

.botoes-cadastro-alterar-senha {
  margin-left: 5px;
}

.area-conteudos {
  border: 1px solid #008c39;
  padding: 10px;
  border-radius: 5px;
  margin-top: 15px;
}

.title-conteudos {
  color: #008c39;
  font-weight: 700;
}

.area-participantes-patrocinador {
  margin-top: 15px;
}
.area-botoes-patrocinador {
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
  width: 85%;
}

.area-imagem-usuario-patrocinador {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.area-imagem-usuario-patrocinador img {
  width: 350px;
  margin-bottom: 5px;
}

.area-botoes-reuniao {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.bt-hora {
  color: #fff;
  font-weight: 600;
  padding: 25px;
  margin: 3px;
  background-color: #6c757d;
  width: 20%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bt-hora:hover {
  background-color: #565e64;
  transition: 150ms;
}

.area-input-filtro {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px;
  width: 100%;
}

.area-filtro-dias {
  display: flex;
  width: 100%;
  justify-content: center;
}
.body-tela-adm {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.area-inputs-adm {
  width: 100%;
}

.area-indicadores {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 15px 0;
}

.indicador {
  padding: 10px;
  border: 1px solid #9dbf1c;
  border-radius: 5px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.tamanho-indicador-1 {
  width: 31%;
}
.tamanho-indicador-2 {
  width: 48%;
}

.indicador-label {
  font-weight: 600;
}
.indicador-value {
  font-size: 29px;
  font-weight: 800;
  color: #007f1d;
}

.icone-menu-adm {
  font-size: 35px;
  position: absolute;
  margin-top: 15px;
  margin-left: 15px;
}

.width-100 {
  width: 100%;
}

.label-dia-reunioes {
  margin: 5px;
  font-weight: 800;
  color: #007f1d;
}
.indicador-relativo {
  position: relative;
}

.mais-detelhes {
  position: absolute;
  right: 8px;
  bottom: 8px;
  text-decoration: none;
  color: #9dbf1c;
}

.patrocinio-cota-adm {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  width: 100%;
}

.cota-adm {
  font-weight: 800;
  color: #007f1d;
}

.area-patrocinador-adm {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
}

.label-nome-patrocinnio {
  font-weight: 800;
  color: #007f1d;
}

.patrocinador-item-adm {
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-bottom: 0 !important;
}

.area-contador {
  margin-right: 25px;
}

.area-body-remarcar {
  display: flex;
  width: 100%;
  padding: 15px;
  justify-content: center;
}
.bloco-remarcar {
  border: 1px solid #6c757d;
  padding: 5px 15px;
  border-radius: 5px;
  margin: 0 15px;
}
.linha-remarcar {
  border-bottom: 1px solid #ccc;
  margin-top: 5px;
  padding: 2px 15px;
  cursor: pointer;
}

.linha-remarcar.ativo {
  background-color: #0d1d41;
  color: #fff;
}

.linha-remarcar.ativo:hover {
  background-color: #264893;
  transition: 250ms;
}

.linha-remarcar:hover {
  background-color: #9dbf1c;
  transition: 250ms;
}

.nome-patrocinador-reunioes {
  color: #007f1d;
  font-weight: 800;
}

.no_show .nome-patrocinador-reunioes {
  color: #fff;
  font-weight: 800;
}

.item-participante-modal:hover .nome-patrocinador-reunioes {
  color: #fff;
}

.entregue .nome-patrocinador-reunioes {
  color: #fff;
}

.marcar-jantar {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bt-marcar-jantar {
  max-width: 250px;
  text-align: center !important;
  margin-bottom: 15px;
}

.dados-eventos {
  border-top: 1px solid #000;
  color: #5c5c5c;
  width: 100%;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 400;
}

.item-participante-modal.entregue .dados-eventos {
  color: #fff;
}
.item-participante-modal:hover .dados-eventos {
  color: #fff;
}

@media (max-width: 424px) {
  .container-adm-main {
    padding: 10px 8px;
  }
  .tamanho-indicador-1 {
    width: 100%;
  }
  .tamanho-indicador-2 {
    width: 100%;
  }

  .btn-header-options {
    margin: 2px;
  }

  .opcoes-lista-adm {
    flex-direction: column;
    align-items: center;
  }
  .opcoes-lista-adm a {
    margin-left: 5px;
    width: fit-content;
    font-size: 13px;
  }
  .simultanias,
  .plano {
    display: none !important;
  }
  .nome-patrocinador-adm,
  .numero-reunioes,
  .opcoes-patrocinadores-adm {
    font-size: 12px;
  }
}

@media (min-width: 425px) {
  .tamanho-indicador-1 {
    width: 30%;
  }

  .indicador-value {
    font-size: 20px;
  }

  .indicador-label {
    font-weight: 600;
    font-size: 13px;
  }

  .mais-detelhes {
    right: 2px;
    bottom: 2px;
  }

  .tamanho-indicador-2 {
    width: 47%;
  }

  .simultanias,
  .plano {
    display: none !important;
  }

  .btn-header-options {
    margin: 2px;
  }

  .opcoes-lista-adm {
    flex-direction: column;
    align-items: center;
  }
  .opcoes-lista-adm a {
    margin-left: 5px;
    width: fit-content;
    font-size: 15px;
  }
}

@media (min-width: 700px) {
  .tamanho-indicador-1 {
    width: 31%;
  }

  .indicador-value {
    font-size: 29px;
  }

  .indicador-label {
    font-weight: 600;
    font-size: 18px;
  }

  .mais-detelhes {
    right: 10px;
    bottom: 8px;
  }

  .tamanho-indicador-2 {
    width: 47%;
  }

  .simultanias,
  .plano {
    display: block !important;
  }

  .opcoes-lista-adm {
    display: flex !important;
    justify-content: center !important;
    flex-direction: row;
  }

  .opcoes-lista-adm a {
    margin-left: 5px;
  }

  .opcoes-lista-adm a svg {
    margin-right: 5px;
  }

  .area-btn-pendentes {
    display: flex;
    flex-direction: column;
  }
}
