.corpo-pagina-chat {
  display: flex;
  justify-content: center;
  align-items: center;
}

.area-lista-conversas {
  border-top: 1px solid rgba(204, 204, 204, 0.281);
  margin-top: 20px;
  padding-top: 20px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.linha-usuario {
  display: flex;
  width: 100%;
  padding: 10px 5px;
  margin-bottom: 2px;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
}

.linha-usuario:hover {
  background-color: #ccc;
  color: #fff;
  cursor: pointer;
  transition: 250ms;
}

.linha-usuario.ativo {
  background-color: #ccc;
  color: #fff;
}

.area-usuario-chat {
  display: flex;
  justify-content: center;
}

.imagem-participante-chat {
  border-radius: 50%;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%) !important;
  height: 2.1rem;
  width: 2.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.dados-usuario-chat {
  font-size: 15px;
  padding-bottom: 5px !important;
  text-transform: uppercase;
  justify-content: center;
  display: flex;
  align-items: center;
}

.quantidade-mensagens {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #018a38;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-weight: 600;
  color: #fff;
  font-size: 15px;
}

.corpo-pagina-chat-conversa {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  position: fixed;
  top: 60px;
  height: 98%;
}

.header-conversa {
  background-color: #018a38;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  position: fixed;
  top: 60px;
}

.body-conversa {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column-reverse;
  /* width: 100%;
  margin-top: 8px;
  height: 100%;
  position: absolute;
  top: 45px;
  overflow: scroll; */

  width: 100%;
  /* margin-top: 8px; */
  height: calc(100% - 80px);
  position: absolute;
  top: 45px;
  overflow: auto;
}

.footer-conversa {
  width: 100%;
  position: absolute;
  bottom: 0;
  /* margin-bottom: 55px; */
  /* border-top: 1px solid #ccc; */
  padding-top: 5px;
  display: flex;
  justify-content: center;
}

.dados-user-conversa {
  display: flex;
  width: 90%;
  padding: 5px;
  align-items: center;
  color: #fff;
}

.texto-user-conversa {
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
}
.botaao-voltar {
  cursor: pointer;
}
.botaao-voltar a {
  color: #fff !important;
  text-decoration: none;
}

.grupo-input-conversa {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96%;
}

.input-mensagem {
  width: 98%;
  padding: 4px 60px 4px 4px;
}

.botao-enviar {
  position: absolute;
  right: 0;
  font-size: 22px;
  color: #fff;
  border-radius: 15px;
  width: 60px;
  background-color: #008c39;
  display: flex;
  justify-content: center;
  padding: 6px;
  cursor: pointer;
  margin-right: 3px;
}

.linha-mensagem {
  display: flex;
  width: 95%;
}

.linha-mensagem:first-child {
  padding-bottom: 25px;
}

.linha-mensagem.user {
  justify-content: flex-end;
}

.mensagem {
  background-color: #ccc;
  padding: 4px 8px;
  margin: 5px 15px;
  border-radius: 5px;
  position: relative;
}

.mensagem.left::after {
  content: "";
  position: absolute;
  left: -23px;
  top: 21px;
  border-right: 15px solid #ccc;
  border-top: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 1px solid transparent;
}

.mensagem.mensagem-user {
  background-color: rgb(238, 236, 236);
  border-bottom-right-radius: 5px;
}

.mensagem.mensagem-user::before {
  content: "";
  position: absolute;
  right: -23px;
  top: 21px;
  border-left: 15px solid rgb(238, 236, 236);
  border-top: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 1px solid transparent;
}
