.area-imagem-logo{
    width: 100%;
    display: flex;
    justify-content: center;
}

.area-imagem-logo img{
    width: 25%;
}

.header-tela-adm-print{
    display: flex;
    justify-content: center;
    padding: 25px;
}

.row-lista-adm-print{
    display: flex;
    width: 100%;
    align-items: center;
    border-radius: 2px;
    padding: 4px;
    transition: 150ms;
    border-bottom: 1px solid #ccc;
    font-size: 12px;
}

.row-lista-adm-print > div {
    width: 100%;
    display: flex;
    justify-content: space-around;
    text-align: center;
    text-transform: uppercase;
}

@media print{
    .espaco-footer {
        display: none !important;
    }

    .row-lista-adm-print{
        font-size: 7px;
    }

    .area-imagem-logo img{
        width: 10%;
    }

    .header-tela-adm-print{
        padding: 15px;
    }

    .container-adm-main{
        margin-top: 0;
    }

}