#bg-fundo-patrocinadores {
  background-attachment: fixed;
  background-image: url("../../assets/2560x1140-azul.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  object-fit: cover;
  /* overflow: hidden; */
  position: absolute;
  width: 100%;
  z-index: -1;
}

.item-portal-patrocinador {
  padding: 8px;
  background-color: #0d1d41;
  color: #fff;
  font-weight: 700;
  margin: 8px 8px 8px 8px;
  border-radius: 16px;
  cursor: pointer;
  border: 1px solid #008a38;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.item-portal-patrocinador:hover {
  background-color: #8abb2a;
  transition: all 200ms;
}
.item-portal-patrocinador:hover .item-portal-detalhe {
  color: #025222;
}

.subtitle {
  color: #adadad;
}

.item-portal-patrocinador small {
  font-size: 13px;
  font-weight: 300;
}

.item-portal-detalhe {
  font-size: 15px;
  font-weight: 500;
  color: #9dbf1c;
}

.area-itens-patrocinadores {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  margin-top: 10px;
}

.area-itens-patrocinadores a {
  width: 100%;
  display: flex;
  justify-content: center;
  text-decoration: none;
  max-width: 700px;
}

.container-patrocinador {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: auto;
  /* justify-content: center; */
  width: 100%;
  position: relative;
  background-image: url("../../assets/login/bg/2560x1140-azul.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-attachment: fixed;
  background-size: cover;
  /* overflow: hidden; */
  object-fit: cover;
  color: #fff;
}

@media (max-width: 1440px) {
  .container-patrocinador {
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;
    height: 100%;
    position: relative;
    background-image: url("../../assets/login/bg/1920x1080-azul.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-attachment: fixed;
    background-size: cover;
    object-fit: cover;
    align-items: center;
  }
}

@media (max-width: 1024px) {
  .container-patrocinador {
    background-image: url("../../assets/login/bg/960x540-azul.png");
    background-size: contain;
    background-color: #0d1b3b;
  }
}

@media (max-width: 1024px) and (min-height: 1366px) {
  .container-patrocinador {
    background-image: url("../../assets/login/bg/2560x1140-azul.png");
    background-position: left;
    background-size: cover;
  }
}

@media (max-width: 853px) {
  .container-patrocinador {
    background-image: url("../../assets/login/bg/2560x1140-azul.png");
    background-position: left;
    background-size: cover;
  }
}

@media (max-width: 768px) {
  .container-patrocinador {
    background-image: url("../../assets/login/bg/2560x1140-azul.png");
    background-position: left;
    background-size: cover;
  }
}
