.container-agradecimento {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  /* overflow: auto; */
  /* justify-content: center; */
  width: 100%;
  position: relative;
  background-image: url("../../assets/login/bg/2560x1140-azul.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-attachment: fixed;
  background-size: cover;
  overflow: hidden;
  object-fit: cover;
  color: #fff;
}

.logo-agradecimento {
  width: 350px;
}

@media (max-width: 1440px) {
  .container-agradecimento {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    width: 100%;
    height: 100%;
    position: relative;
    background-image: url("../../assets/login/bg/1920x1080-azul.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-attachment: fixed;
    background-size: cover;
    overflow: hidden;
    object-fit: cover;
    align-items: center;
  }
}

@media (max-width: 1024px) {
  .container-agradecimento {
    background-image: url("../../assets/login/bg/960x540-azul.png");
    background-size: contain;
    background-color: #0d1b3b;
  }

  .logo-agradecimento {
    width: 350px;
    margin-bottom: 8px;
  }
}

@media (max-width: 1024px) and (min-height: 1366px) {
  .container-agradecimento {
    background-image: url("../../assets/login/bg/2560x1140-azul.png");
    background-position: left;
    background-size: cover;
  }

  .logo-agradecimento {
    width: 350px;
    margin-bottom: 8px;
  }
}

@media (max-width: 853px) {
  .container-agradecimento {
    background-image: url("../../assets/login/bg/2560x1140-azul.png");
    background-position: left;
    background-size: cover;
  }

  .logo-agradecimento {
    width: 350px;
    margin-bottom: 8px;
  }
}

@media (max-width: 768px) {
  .container-agradecimento {
    background-image: url("../../assets/login/bg/2560x1140-azul.png");
    background-position: left;
    background-size: cover;
  }

  .logo-agradecimento {
    width: 350px;
    margin-bottom: 8px;
  }
}
